<template>
  <b-card-code title="Ajouter un nouveau groupe">
    <b-card-text>
      <span>Veuillez saisir les coordonnées du Groupe </span>
    </b-card-text>

    <!-- form -->
    <validation-observer ref="simpleRules">
      <b-form>
        <b-row>
          <b-col md="6">
            <b-form-group
              label="Code salle"
              label-for="Code salle"
            >
              <validation-provider
                #default="{ errors }"
                name="Code v"
                rules="required"
              >
                <b-form-input
                  v-model="salles.room_code"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Code salle"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="TypeSalle"
              label-for="TypeSalle"
            >
              <validation-provider
                #default="{ errors }"
                name="TypeSalle"
                rules="required"
              >
                <v-select
                  v-model="salles.type_room"
                  :clearable="false"
                  :options="typeRoom"
                  label="title"
                  placeholder="TypeSalle"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group
              label="Capacite"
              label-for="Capacite"
            >
              <validation-provider
                #default="{ errors }"
                name="Capacite"
                rules="required"
              >
                <b-form-input
                  v-model="salles.capacity"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Capacite"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-button
              variant="primary"
              type="submit"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              @click.prevent="addSalle"
            >
              Ajouter
            </b-button>
            <b-button
              variant="outline-secondary"
              type="reset"
            >
              Réinitialiser
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card-code>
</template>

<script>
import axios from 'axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import BCardCode from '@core/components/b-card-code'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'

import {
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BButton,
  BCardText /*  */,
} from 'bootstrap-vue'
import { required } from '@validations'

export default {
  components: {
    BCardCode,
    ValidationProvider,
    ValidationObserver,
    BCardText,
    /* BFormTextarea, */
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    vSelect,
  },
  data() {
    return {
      password: '',

      salles: {
        room_code: '',
        type_room: '',
        capacity: '',
      },
      required,
      typeRoom: ['salle info', 'labo info', 'labo technique'],
    }
  },

  methods: {
    async addSalle() {
      this.$refs.simpleRules.validate().then(async success => {
        if (success) {
          try {
            await axios
              .post(
                '/api/rooms/create/',
                {
                  room_code: this.salles.room_code,
                  type_room: this.salles.type_room,
                  capacity: this.salles.capacity,
                },
              )
            this.$router.push('/rooms')
            setTimeout(() => {
              this.showToast('success', 'top-center', 'Salle ajouté avec succés')
            }, 1000)
          } catch (err) {
            setTimeout(() => {
              this.showToast('danger', 'top-center', err.toString())
            }, 1000)
          }
        }
      })
    },

    showToast(variant, position, text) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: 'Notification',
            icon: 'InfoIcon',
            text,
            variant,
          },
        },
        {
          position,
        },
      )
    },

  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
